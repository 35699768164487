<template>
  <v-container fluid class="px-4 simulator-container">
    <!-- Header Section -->
    <v-card flat class="mb-6 rounded-lg campaign-header-card">
      <v-card-text>
        <div class="d-flex flex-row align-center justify-space-between">
          <div class="d-flex align-center">
          <v-btn
              class="mr-4"
              color="primary"
              outlined
            @click="
              () =>
                this.$router.push({
                  path: `/abstract-campaign/${this.$route.params.id1}/campaign/${this.$route.params.id2}`,
                })
            "
          >
              <v-icon left>mdi-arrow-left</v-icon>
              <span>Volver</span>
          </v-btn>
            <h1 class="text-h4 font-weight-light">
            Simulador {{ processedSingleCampaign.processedName }}
          </h1>
          </div>
          <div class="d-flex align-center">
            <v-tooltip v-if="!hasActiveCampaignModifiers" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="mr-3"
                  :disabled="isUserManager || !userHasLock"
                  text
                v-bind="attrs"
                v-on="on"
              >
                  <v-icon left>mdi-restart</v-icon>
                  Reestablecer
                </v-btn>
            </template>
              <span>No hay modificadores activos</span>
          </v-tooltip>
            <v-tooltip v-else bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  :disabled="isUserManager || !userHasLock"
                  color="error"
                  outlined
                v-bind="attrs"
                v-on="on"
                @click="resetAllModifiers"
              >
                  <v-icon left>mdi-restart-alert</v-icon>
                  Reestablecer Todo
                </v-btn>
            </template>
              <span>Esta acción eliminará TODOS los modificadores</span>
          </v-tooltip>
        </div>
        </div>
      </v-card-text>
    </v-card>

    <!-- KPI Cards Section -->
    <div class="d-flex flex-column mb-6">
      <v-card flat class="mb-3 rounded-lg kpi-card">
        <v-card-title class="text-subtitle-1 grey--text">
          <v-icon left color="primary">mdi-chart-line</v-icon>
          KPIs de Campaña
        </v-card-title>
        <v-card-text>
          <InfoGrid :infos="campaignKpiWithoutPct" valueClass="text-h4 font-weight-medium" />
        </v-card-text>
      </v-card>
      
      <v-card flat class="rounded-lg kpi-card">
        <v-card-title class="text-subtitle-1 grey--text">
          <v-icon left color="success">mdi-chart-bar</v-icon>
          KPIs de Simulación
        </v-card-title>
        <v-card-text>
          <InfoGrid :infos="campaignSimulationKpiWithPct" valueClass="text-h4 font-weight-medium" />
        </v-card-text>
      </v-card>
    </div>

    <!-- Lock Status and Tabs -->
    <v-card flat class="mb-6 rounded-lg tabs-card">
      <v-card-text>
        <div class="d-flex flex-row align-center">
          <!-- Lock Status -->
          <div class="mr-4 d-flex align-center">
            <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-avatar size="36" class="mr-2" v-bind="attrs" v-on="on">
                  <v-icon v-if="!isLock" size="22" color="success">mdi-lock-open-outline</v-icon>
                  <v-icon v-else size="22" color="error">mdi-lock-outline</v-icon>
                </v-avatar>
            </template>
            <span v-if="!isLock">Desbloqueado</span>
            <span v-else>Bloqueado</span>
          </v-tooltip>

            <div v-if="isLock && !userHasLock" class="d-flex align-center">
              <v-chip small color="primary" outlined class="mr-2">
                <v-avatar left>
                  <v-icon small class="blink">mdi-account</v-icon>
                </v-avatar>
                {{ userWithLockUsername }}
              </v-chip>
              <v-chip small color="warning" outlined>
                <v-avatar left>
                  <v-icon small>mdi-clock-outline</v-icon>
                </v-avatar>
                {{ simulationLockDate }}
              </v-chip>
            </div>
          </div>

          <!-- Tabs -->
          <v-tabs v-model="tab" background-color="transparent" class="flex-grow-1" slider-color="primary">
            <v-tab
              @click="handleTabChange(yieldTab)"
              class="text-none"
            >
              <v-icon left>mdi-sprout</v-icon>
              Rinde
            </v-tab>
            <v-tab
              @click="handleTabChange(priceTab)"
              class="text-none"
            >
              <v-icon left>mdi-currency-usd</v-icon>
              Precio
            </v-tab>
            <v-tab
              @click="handleTabChange(costItemFamilyTab)"
              class="text-none"
            >
              <v-icon left>mdi-tag-multiple</v-icon>
              Costos de Familia
            </v-tab>
            <v-tab
              @click="handleTabChange(freightTab)"
              class="text-none"
            >
              <v-icon left>mdi-truck</v-icon>
              Flete
            </v-tab>
            <v-tab
              @click="handleTabChange(rentTab)"
              class="text-none"
            >
              <v-icon left>mdi-home-city</v-icon>
              Alquiler Quintales Fijos
            </v-tab>
            <v-tab
              @click="handleTabChange(cashflowTab)"
              class="text-none"
            >
              <v-icon left>mdi-cash-multiple</v-icon>
              Cashflow de Simulación
            </v-tab>
          </v-tabs>
          
          <!-- Lock Control Buttons -->
          <div class="ml-3">
            <v-tooltip v-if="userHasLock" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                  icon
                color="primary"
                @click="releaseUserLock"
              >
                  <v-icon>mdi-lock-open-check-outline</v-icon>
              </v-btn>
            </template>
            <span>Desbloquear para otros usuarios</span>
          </v-tooltip>
            <v-tooltip v-if="!isLock" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                  icon
                color="warning"
                @click="setUserLockIfNotSet"
              >
                  <v-icon>mdi-pen-lock</v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>
        </div>
              </div>
      </v-card-text>
    </v-card>

    <!-- Tab Content -->
    <v-tabs-items v-model="tab" class="rounded-lg content-card">
          <v-overlay
            v-if="showLockOverlay"
            :opacity="0.08"
        class="lock-overlay rounded-lg"
            absolute
          >
        <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  color="primary"
                  :disabled="isUserManager"
                  @click="setUserIdLock"
              fab
                >
              <v-icon size="24">mdi-lock-outline</v-icon>
                </v-btn>
              </template>
              <span>Desbloquear</span>
            </v-tooltip>
          </v-overlay>

      <!-- Tabs content except cashflow -->
      <v-tab-item v-for="tabIndex in 5" :key="tabIndex">
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-card flat class="rounded-lg filter-card pa-4">
                  <div class="d-flex flex-wrap align-center">
                    <v-select
                      label="Mostrar"
                      :items="[
                        { name: 'Todos', value: 'all' },
                        { name: 'Activos', value: 'activeOnly' },
                        { name: 'Inactivos', value: 'inActiveOnly' },
                      ]"
                      item-text="name"
                      item-value="value"
                      v-model="modifiersTypeFilter"
                      @change="exitEdit"
                      dense
                      outlined
                      hide-details
                      class="mr-2 filter-select"
                      style="max-width: 150px;"
                    ></v-select>
                    <v-select
                      label="Region"
                      :items="regions"
                      item-text="abbreviation"
                      item-value="id"
                      v-model="regionIdFilter"
                      @change="exitEdit"
                      dense
                      outlined
                      clearable
                      hide-details
                      class="mr-2 filter-select"
                      style="max-width: 150px;"
                    ></v-select>
                    <v-select
                      v-if="tab !== rentTab"
                      label="Especie"
                      :items="species"
                      item-text="name"
                      item-value="id"
                      v-model="speciesIdFilter"
                      @change="handleActivitySpeciesChange"
                      clearable
                      dense
                      outlined
                      hide-details
                      class="mr-2 filter-select"
                      style="max-width: 180px;"
                    ></v-select>
                    <v-select
                      v-if="tab !== rentTab"
                      label="Actividad"
                      :items="activities"
                      item-text="name"
                      item-value="id"
                      v-model="activityIdFilter"
                      @change="handleActivitySpeciesChange"
                      clearable
                      dense
                      outlined
                      hide-details
                      class="mr-2 filter-select"
                      style="max-width: 180px;"
                    ></v-select>
                    <v-select
                      v-if="tab !== rentTab && tab === costItemFamilyTab"
                      label="Familia"
                      :items="costItemFamiliesFiltered"
                      item-text="name"
                      item-value="id"
                      v-model="costItemFamilyIdFilter"
                      @change="exitEdit"
                      clearable
                      dense
                      outlined
                      hide-details
                      class="mr-2 filter-select"
                      style="max-width: 180px;"
                    ></v-select>
                    <v-btn
                      class="ml-auto"
                      outlined
                      color="info"
                      :disabled="
                        !regionIdFilter &&
                        (tab === rentTab || (!speciesIdFilter && !activityIdFilter && !costItemFamilyIdFilter)) &&
                        modifiersTypeFilter === 'all'
                      "
                      @click="
                        speciesIdFilter = null;
                        regionIdFilter = null;
                        activityIdFilter = null;
                        costItemFamilyIdFilter = null;
                        modifiersTypeFilter = 'all';
                        exitEdit();
                      "
                    >
                      <v-icon left>mdi-restart</v-icon>
                      Limpiar Filtros
                      </v-btn>
                  </div>
                </v-card>

                <!-- Modifier Control Panel -->
                <v-card class="mt-4 mb-4 rounded-lg control-card pa-4">
                <div class="d-flex flex-column">
                    <!-- Slider row -->
                    <div class="d-flex align-center">
                      <v-icon color="primary" class="mr-3">{{ tabIndex === 5 ? 'mdi-home-edit' : 'mdi-percent' }}</v-icon>
                      
                      <div class="flex-grow-1 mr-4">
                      <v-slider
                          thumb-label="always"
                          :min="tabIndex === 5 ? -20 : 20"
                          :max="tabIndex === 5 ? 20 : 200"
                          :step="tabIndex === 5 ? 1 : 5"
                          tick-size="4"
                          :value="tabIndex === 5 ? rentModsNewValue : modsNewValue"
                          @input="tabIndex === 5 ? rentModsNewValue = $event : modsNewValue = $event"
                        :disabled="!allowEdit"
                        color="primary"
                          track-color="grey lighten-3"
                          class="mt-1"
                      >
                        <template v-slot:thumb-label="{ value }">
                          {{ value }}{{ tabIndex === 5 ? 'qq/ha' : '%' }}
                        </template>
                      </v-slider>
                  </div>

                        <v-text-field
                        :value="tabIndex === 5 ? rentModsNewValue : modsNewValue"
                        @input="tabIndex === 5 ? rentModsNewValue = $event : modsNewValue = $event"
                          dense
                        :ref="`inputTest${tabIndex}`"
                          type="number"
                          outlined
                        :suffix="tabIndex === 5 ? 'qq/ha' : '%'"
                          :readonly="!allowEdit"
                        :rules="tabIndex === 5 ? rentModRules : modRules"
                        hide-details
                        style="max-width: 120px;"
                        class="mr-4"
                      ></v-text-field>

                      <v-btn
                        v-if="allowEdit"
                        color="success"
                        @click="saveEdits()"
                        :loading="isPageLoading"
                      >
                        <v-icon left>mdi-content-save</v-icon>
                        Guardar
                      </v-btn>

                      <v-tooltip v-if="filteredModTableData.length === 0" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            disabled
                            text
                          >
                            {{ editText }}
                          </v-btn>
                        </template>
                        <span>Ningún filtro seleccionado</span>
                      </v-tooltip>
                      
                      <v-btn
                        v-else-if="!allowEdit"
                        :color="editButtonColor"
                        :disabled="isUserManager"
                        @click="enterEdit()"
                      >
                        <v-icon left>mdi-pencil</v-icon>
                        {{ editText }}
                      </v-btn>
                      
                      <v-btn
                        v-else
                        color="grey darken-1"
                        text
                        @click="exitEdit()"
                      >
                        <v-icon left>mdi-close</v-icon>
                        Cancelar
                      </v-btn>
                  </div>

                    <!-- Warning text -->
                    <div v-if="alertUserModifiersFiltersApplied && allowEdit" class="mt-2">
                      <v-alert
                          dense
                        text
                        type="warning"
                        color="warning"
                        class="mb-0 py-1"
                      >
                        {{ alertUserModifiersFiltersApplied }}
                      </v-alert>
                    </div>
                  </div>
                </v-card>

                <!-- Reset Selected Button and Results Count -->
                <div class="d-flex justify-space-between align-center mb-3">
                  <v-tooltip v-if="activeSelectedModifiers.length === 0" bottom>
            <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
              <v-btn
                          color="grey lighten-3"
                :disabled="isUserManager || !userHasLock"
                          text
              >
                          <v-icon left>mdi-delete-outline</v-icon>
                          Reestablecer Seleccionados
                        </v-btn>
                      </div>
            </template>
                    <span>Ningún modificador activo seleccionado</span>
          </v-tooltip>
                  
          <v-btn
            v-else
                    color="error"
                    outlined
            :disabled="isUserManager || !userHasLock"
            @click="resetSelectedModifiers"
                  >
                    <v-icon left>mdi-delete-outline</v-icon>
                    Reestablecer Seleccionados
                  </v-btn>
                  
                  <div class="d-flex align-center">
                    <v-icon small color="grey" class="mr-1">mdi-filter-variant</v-icon>
                    <span class="grey--text text--darken-1">
                      Resultados: {{ filteredModTableData.length }}
          </span>
        </div>
                </div>

                <!-- Data Table -->
                <v-card class="rounded-lg">
        <AbstractDraftMetricDataTable
          :title="'Modificadores por ' + modTableTypeName"
          :hideFooter="false"
          orderBy="parsedModifier"
          :desc="true"
          :items="filteredModTableData"
          :headers="modTableHeaders"
          :headersWidth="100"
          :hasSearch="false"
          :grupByStatus="false"
        />
                </v-card>
      </v-col>
    </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- Cashflow Tab -->
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <DenseCashflowTable
              :rows="engagementCashflowRows"
              :totalHaToolTip="campaignTotalHaToolTip"
              :columns="engagementCashflowColumns"
              :isResourceUtilization="false"
              :startDate="campaignStartDate"
              :endDate="campaignEndDate"
            />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import AbstractDraftMetricDataTable from "../components/Abstract/AbstractDraftMetricDataTable.vue";
import { unPackAndProcessCashflowKeys } from "../utils.js";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../managrx/serverCall.js";
import { campaignLevelTotalHaToolTip } from "../managrx/constants.js";
import { makeCampaignSimulationInfoGrid } from "../managrx/services.js";
import InfoGrid from "../components/Project/InfoGrid.vue";
import DenseCashflowTable from "../components/WIP/DenseCashflowTable.vue";
import { parseToLocaleString } from "../managrx/utils.js";

export default {
  name: "SingleCampaignSimulation",
  data() {
    return {
      userWithLock: null,
      showOpenLock: false,
      panel: [0],
      cashflowTab: 5,
      rentTab: 4,
      freightTab: 3,
      costItemFamilyTab: 2,
      priceTab: 1,
      yieldTab: 0,
      isFullLoad: this.$route.meta.isFullLoad,
      tab: "",
      parentTab: "",
      modifiersTypeFilter: "all",
      regionIdFilter: null,
      speciesIdFilter: null,
      costItemFamilyIdFilter: null,
      modifierFilter: null,
      makeActivityGroup: false,
      search: null,
      changeUnitsButtonTagT1: "USD",
      isUsdTotalMetricT1: false,
      metricUnitsT1: "USD/ha",
      changeUnitsButtonTagT3: "USD",
      isUsdTotalMetricT3: false,
      metricUnitsT3: "USD/ha",
      showCampaignDetail: false,
      showMetrics: false,
      showPrices: false,
      showCashflows: false,
      showEngagements: true,
      showActions: false,
      showNewEngagement: false,
      allowEdit: false,
      editText: "Editar",
      editButtonColor: "warning",
      modsNewValue: 100,
      rentModsNewValue: 1,
      activityIdFilter: null,
      // Simulations
      rentModRules: [
        (v) => (!!v && v !== 0) || "El valor no puede ser nulo",
        (v) => (v && v >= -20) || "No puede ser menor a -20qq/ha",
        (v) => (v && v <= 20) || "No puede ser mayor a 20qq/ha",
      ],
      modRules: [
        (v) => (!!v && v !== 0) || "El valor no puede ser nulo",
        (v) => (v && v >= 20) || "No puede ser menor a 20%",
        (v) => (v && v <= 200) || "No puede ser mayor a 200%",
      ],

      yieldModifiersDataTableHeaders: [
        {
          text: "Region",
          value: "regionName",
        },
        {
          text: "Actividad",
          value: "activityName",
        },
        {
          text: "Modificador de Rinde",
          value: "parsedModifier",
        },
      ],
      freightModifiersDataTableHeaders: [
        {
          text: "Region",
          value: "regionName",
        },
        {
          text: "Actividad",
          value: "activityName",
        },
        {
          text: "Modificador de Flete",
          value: "parsedModifier",
        },
      ],
      rentModifiersDataTableHeaders: [
        {
          text: "Region",
          value: "regionName",
        },
        {
          text: "Modificador de Alquiler",
          value: "parsedModifier",
        },
      ],
      priceModifiersDataTableHeaders: [
        {
          text: "Actividad",
          value: "activityName",
        },
        {
          text: "Modificador de Precio",
          value: "parsedModifier",
        },
      ],
      costItemFamilyModifiersDataTableHeaders: [
        {
          text: "Region",
          value: "regionName",
        },
        {
          text: "Actividad",
          value: "activityName",
        },
        {
          text: "Familia",
          value: "costItemFamilyName",
        },
        {
          text: "Modificador de Familia",
          value: "parsedModifier",
        },
      ],
      costItemModifiers: [],
    };
  },
  methods: {
    async releaseUserLock() {
      await this.$store.dispatch("getSingleCampaign", this.$route.params.id2);
      if (this.userId == this.singleCampaign?.userIdSimulationLock) {
        let response = await putRequest(`/campaign/${this.singleCampaign.id}`, {
          userIdSimulationLock: null,
          userLockDatetime: null,
        });
        if (!response.success) {
          this.$store.dispatch("setNewNotification", response);
        }
      }
      this.refreshRoute();
      this.exitEdit();
    },
    async getUserLockName() {
      if (this.singleCampaign?.userIdSimulationLock && !this.userHasLock) {
        let response = await getRequest(
          `/user/all/${this.singleCampaign.userIdSimulationLock}`
        );
        if (response.success) {
          this.userWithLock = response.data;
        } else {
          this.$store.dispatch("setNewNotification", response);
        }
      }
    },
    async setUserIdLock() {
      this.$store.dispatch("setPageLoadingStatus", true);
      let response = await putRequest(`/campaign/${this.singleCampaign.id}`, {
        userIdSimulationLock: this.userId,
        userLockDatetime: new Date().toISOString(),
      });
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      }
      this.refreshRoute();
      this.exitEdit();

      this.$store.dispatch("setPageLoadingStatus", false);
    },
    async getAllActiveModifiers() {
      // Add debug logs
      console.log("Fetching modifiers for campaign:", this.$route.params.id2);
      
      // Fetch yield modifiers
      await this.$store.dispatch(
        "getYieldModifiersByCampaignId",
        this.$route.params.id2
      );
      console.log("Fetched yield modifiers:", this.yieldModifiers);
      
      // Fetch other modifiers
      await this.$store.dispatch(
        "getPriceModifiersByCampaignId",
        this.$route.params.id2
      );
      await this.$store.dispatch(
        "getCostItemFamilyModifiersByCampaignId",
        this.$route.params.id2
      );
      await this.$store.dispatch(
        "getFreightModifiersByCampaignId",
        this.$route.params.id2
      );
      await this.$store.dispatch(
        "getRentModifiersByCampaignId",
        this.$route.params.id2
      );
    },
    checkForKeyShortCut(event) {
      if (
        (this.isLock && !this.userHasLock) ||
        !this.isLock ||
        this.isUserManager
      ) {
        return;
      } else {
        // Press `ctrl+e` to enter in edit mode
        if (event.ctrlKey && event.key === "e") {
          event.preventDefault();
          if (this.filteredModTableData?.length > 0) {
            this.enterEdit();
          }
        }
        // Press `escape` to exit without saving
        if (event.key === "Escape" && this.allowEdit) {
          event.preventDefault();
          this.resetValues();
          this.exitEdit();
        }
        // Press `enter` to save
        if (event.keyCode === 13 && this.allowEdit) {
          event.preventDefault();
          this.saveEdits();
          this.exitEdit();
        }
      }
    },
    resetValues() {
      // Reset rent modifiers to 0 (neutral value)
      this.rentModsNewValue = 0;
      // Reset percentage modifiers to 100% (neutral value)
      this.modsNewValue = 100;
    },
    saveEdits() {
      this.updateActiveModifiers();
      this.addNewModifiers();
    },
    refreshRoute() {
      this.$store.dispatch("setPageLoadingStatus", true);
      this.$store.dispatch("getSingleCampaign", this.$route.params.id2);
      this.getAllActiveModifiers();
      if (!this.isCampaignActivitiesEmpty) {
        this.getCampaignSimulationCashflow();
        this.getCampaignSimulationMetrics();
        this.getCampaignMetrics();
      }
      this.getUserLockName();
      this.$store.dispatch("setPageLoadingStatus", false);
    },
    async setUserLockIfNotSet() {
      if (this.isLock && this.userHasLock) {
        // User already has the lock
        return;
      } else if (this.isLock && !this.userHasLock) {
        // User needs to unlock first
        return;
      } else {
        // Refresh Lock information
        await this.$store.dispatch("getSingleCampaign", this.campaignId);

        if (this.singleCampaign?.userIdSimulationLock != null) {
          // Lock is already set, refrsh to show to the user
          this.refreshRoute();
        } else {
          // Lock is not set, user gets the lock by editing
          this.setUserIdLock();
        }
      }
    },
    async enterEdit() {
      if (!this.isUserManager) {
        this.allowEdit = true;
        this.editText = "Cancelar";
        this.editButtonColor = "info";
      }
    },
    exitEdit() {
      this.allowEdit = false;
      this.editButtonColor = "warning";
      this.editText = "Editar";
    },
    async getCampaignSimulationCashflow() {
      this.$store.dispatch("setPageLoadingStatus", true);

      await this.$store.dispatch("getCampaignSimulationCashflow", {
        startYear: this.cashflowStartYear,
        startMonth: this.cashflowStartMonth,
        endYear: this.cashflowEndYear,
        endMonth: this.cashflowEndMonth,
        campaignId: this.$route.params.id2,
      });
      this.$store.dispatch("setPageLoadingStatus", false);
    },
    async getCampaignSimulationMetrics() {
      if (!this.isCampaignActivitiesEmpty) {
        this.$store.dispatch("getCampaignSimulationMetrics", {
          startYear: this.cashflowStartYear,
          startMonth: this.cashflowStartMonth,
          endYear: this.cashflowEndYear,
          endMonth: this.cashflowEndMonth,
          campaignId: this.$route.params.id2,
        });
      }
    },
    async getCampaignMetrics() {
      if (!this.isCampaignActivitiesEmpty) {
        this.$store.dispatch("getCampaignMetrics", {
          startYear: this.cashflowStartYear,
          startMonth: this.cashflowStartMonth,
          endYear: this.cashflowEndYear,
          endMonth: this.cashflowEndMonth,
          campaignId: this.$route.params.id2,
        });
      }
    },
    async updateActiveModifiers() {
      this.$store.dispatch("setPageLoadingStatus", true);
      if (this.activeSelectedModifiers?.length > 0) {
        let endpoint = "/module/put/simulation" + this.modTableEndpoint + `/${this.campaignId}`;
        let payload;
        if (this.tab == this.rentTab) {
          // For RENT TAB, the reset value is 0qq
          if (this.rentModsNewValue === 0) {
            this.resetSelectedModifiers();
          } else {
            payload = {
              bulkUpdate: this.activeSelectedModifiers.map((el) => {
                return { id: el?.id, modifier: this.rentModsNewValue };
              }),
            };
          }
        } else {
          // For OTHERS TABS the reset value is 100%
          if (this.modsNewValue === 100) {
            this.resetSelectedModifiers();
          } else {
            payload = {
              bulkUpdate: this.activeSelectedModifiers.map((el) => {
                return { id: el?.id, modifier: this.modsNewValue / 100 };
              }),
            };
          }
        }
        if (payload) {
          let result = await putRequest(endpoint, payload);
          if (!result?.success) {
            this.$store.dispatch("setNewNotification", result);
          }
        }
      }
      this.refreshRoute();
      this.exitEdit();
      this.$store.dispatch("setPageLoadingStatus", false);
    },
    async addNewModifiers() {
      this.$store.dispatch("setPageLoadingStatus", true);
      if (this.inActiveSelectedModifiers?.length > 0) {
        let endpoint =
          "/module/post/simulation" +
          this.modTableEndpoint +
          `/${this.campaignId}`;
        let payload;
        // Only add new if the selected value is not a reset
        // For RENT TAB, the reset value is 0qq
        // For OTHERS TABS the reset value is 100%
        if (
          (this.tab === this.rentTab && this.rentModsNewValue !== 0) ||
          (this.tab !== this.rentTab && this.modsNewValue !== 100)
        ) {
          if (this.tab == this.yieldTab || this.tab == this.freightTab) {
            // Yield and Freight Modifiers
            // Create a Set to track unique combinations of activityId and regionId
            const uniqueCombinations = new Set();
            
            payload = {
              bulkNew: this.inActiveSelectedModifiers
                .filter(el => {
                  // Create a unique key for this combination
                  const key = `${el.activityId}_${el.regionId}`;
                  
                  // If we've seen this combination before, skip this element
                  if (uniqueCombinations.has(key)) {
                    return false;
                  }
                  
                  // Otherwise, add it to our set and keep this element
                  uniqueCombinations.add(key);
                  return true;
                })
                .map((el) => {
                  return {
                    campaignId: this.campaignId,
                    activityId: el?.activityId,
                    regionId: el?.regionId,
                    modifier: this.modsNewValue / 100,
                  };
                }),
            };
          } else if (this.tab == this.priceTab) {
            // Price modifiers
            // Create a Set to track unique activityIds
            const uniqueActivities = new Set();
            
            payload = {
              bulkNew: this.inActiveSelectedModifiers
                .filter(el => {
                  // If we've seen this activity before, skip this element
                  if (uniqueActivities.has(el.activityId)) {
                    return false;
                  }
                  
                  // Otherwise, add it to our set and keep this element
                  uniqueActivities.add(el.activityId);
                  return true;
                })
                .map((el) => {
                  return {
                    campaignId: this.campaignId,
                    activityId: el?.activityId,
                    modifier: this.modsNewValue / 100,
                  };
                }),
            };
          } else if (this.tab == this.costItemFamilyTab) {
            // Cost item family Modifiers
            // Create a Set to track unique combinations of activityId, regionId, and costItemFamilyId
            const uniqueCombinations = new Set();
            
            payload = {
              bulkNew: this.inActiveSelectedModifiers
                .filter(el => {
                  // Create a unique key for this combination
                  const key = `${el.activityId}_${el.regionId}_${el.costItemFamilyId}`;
                  
                  // If we've seen this combination before, skip this element
                  if (uniqueCombinations.has(key)) {
                    return false;
                  }
                  
                  // Otherwise, add it to our set and keep this element
                  uniqueCombinations.add(key);
                  return true;
                })
                .map((el) => {
                  return {
                    campaignId: this.campaignId,
                    activityId: el?.activityId,
                    regionId: el?.regionId,
                    costItemFamilyId: el?.costItemFamilyId,
                    modifier: this.modsNewValue / 100,
                  };
                }),
            };
          } else if (this.tab == this.rentTab) {
            // Rent modifiers - these still use regionId only
            // Create a Set to track unique regionIds
            const uniqueRegions = new Set();
            
            payload = {
              bulkNew: this.inActiveSelectedModifiers
                .filter(el => {
                  // If we've seen this region before, skip this element
                  if (uniqueRegions.has(el.regionId)) {
                    return false;
                  }
                  
                  // Otherwise, add it to our set and keep this element
                  uniqueRegions.add(el.regionId);
                  return true;
                })
                .map((el) => {
                  return {
                    campaignId: this.campaignId,
                    regionId: el?.regionId,
                    modifier: this.rentModsNewValue,
                  };
                }),
            };
          }
          if (payload) {
            // Log the payload for debugging
            console.log("Sending payload:", payload);
            
            let result = await postRequest(endpoint, payload);
            if (!result?.success) {
              this.$store.dispatch("setNewNotification", result);
            }
          }
        } else {
          this.exitEdit();
        }
      }
      this.refreshRoute();
      this.exitEdit();
      this.$store.dispatch("setPageLoadingStatus", false);
    },
    async resetAllModifiers() {
      this.$store.dispatch("setPageLoadingStatus", true);
      if (this.hasActiveCampaignModifiers > 0) {
        let requestArray = [];
        requestArray.push(
          deleteRequest(`/module/delete/simulation/yield/${this.campaignId}`)
        );

        requestArray.push(
          deleteRequest(`/module/delete/simulation/price/${this.campaignId}`)
        );

        requestArray.push(
          deleteRequest(
            `/module/delete/simulation/cost_item_family/${this.campaignId}`
          )
        );

        requestArray.push(
          deleteRequest(`/module/delete/simulation/freight/${this.campaignId}`)
        );

        requestArray.push(
          deleteRequest(`/module/delete/simulation/rent/${this.campaignId}`)
        );

        let responseArray = await Promise.all(requestArray);
        for (const response of responseArray) {
          if (!response?.success) {
            this.$store.dispatch("setNewNotification", response);
          }
        }
        
        // Reset the modifier values
        this.resetValues();
        
        this.refreshRoute();
        this.exitEdit();
        this.$store.dispatch("setPageLoadingStatus", false);
      }
    },
    async resetSelectedModifiers() {
      this.$store.dispatch("setPageLoadingStatus", true);
      if (this.activeSelectedModifiers?.length > 0) {
        let endpoint = "/module/delete/simulation" + this.modTableEndpoint + `/${this.campaignId}`;

        if (this.deleteQueryParams) {
          endpoint = endpoint + `?q=${this.deleteQueryParams}`;
        }
        let response = await deleteRequest(endpoint);
        if (!response?.success) {
          this.$store.dispatch("setNewNotification", response);
        }
        
        // Reset the modifier values
        this.resetValues();
      }
      this.refreshRoute();
      this.exitEdit();
      this.$store.dispatch("setPageLoadingStatus", false);
    },
    resetFilters() {
      this.speciesIdFilter = null;
      this.activityIdFilter = null;
      this.regionIdFilter = null;
      this.costItemFamilyIdFilter = null;  // Make sure this is included
      this.modifiersTypeFilter = 'all';
    },
    handleActivitySpeciesChange() {
      // If the user selected an activity, clear the species filter
      if (this.activityIdFilter) {
        this.speciesIdFilter = null;
      }
      // If the user selected a species, clear the activity filter
      else if (this.speciesIdFilter) {
        this.activityIdFilter = null;
      }
      this.exitEdit();
    },
    async handleTabChange(newTab) {
      // Reset filters and values for all tabs
      this.resetFilters();
      this.resetValues();
      this.exitEdit();

      // If switching to cashflow tab, load the data
      if (newTab === this.cashflowTab) {
        this.$store.dispatch("setPageLoadingStatus", true);
        await this.getCampaignSimulationCashflow();
        this.$store.dispatch("setPageLoadingStatus", false);
      }
    },
  },
  computed: {
    ...mapGetters([
      "userTokenInfo",
      "campaignSimulationMetrics",
      "singleCampaign",
      "singleAbstractCampaign",
      "campaignModifiers",
      "campaignMetrics",
      "costItemFamilies",
      "regions",
      "species",
      "activities",
      "yieldModifiers",
      "freightModifiers",
      "rentModifiers",
      "priceModifiers",
      "costItemFamilyModifiers",
      "campaignActivities",
      "isPageLoading",
      "campaignCashflow",
    ]),
    simulationLockDate() {
      return this.processedSingleCampaign?.simulationLockDate;
    },
    userWithLockUsername() {
      if (this.userWithLock != null) {
        return this.userWithLock?.username;
      } else {
        return "";
      }
    },

    showLockOverlay() {
      return !this.userHasLock && this.tab !== this.cashflowTab;
    },
    isUnlock() {
      return this.isLock === false;
    },
    costItemFamiliesFiltered() {
      return this.costItemFamilies.filter((el) => el.name !== "Fletes");
    },
    deleteQueryParams() {
      let q = [];
      if (this.speciesIdFilter) {
        q.push(`species_id:${this.speciesIdFilter}`);
      }
      if (this.activityIdFilter) {
        q.push(`activity_id:${this.activityIdFilter}`);
      }
      if (this.costItemFamilyIdFilter) {
        q.push(`cost_item_family_id:${this.costItemFamilyIdFilter}`);
      }
      if (this.regionIdFilter) {
        q.push(`region_id:${this.regionIdFilter}`);
      }
      return q.join(",");
    },
    modifiersTableData() {
      console.log("Activities:", this.activities);
      console.log("Yield modifiers raw:", this.yieldModifiers);
      
      // Check if essential data is loaded
      if (!this.activities || !this.activities.length) {
        console.warn("No activities available");
        return {
          yield: [],
          freight: [],
          rent: [],
          costItemFamily: [],
        };
      }
      
      let rentModifiersTableData = [];
      let yieldModifiersTableData = [];
      let freightModifiersTableData = [];
      let costItemFamilyModifiersTableData = [];

      // Rent modifiers remain the same (region-based)
      for (const region of this.regions) {
        // Rent Modifier section (unchanged)
        let rentActiveMod = this.rentModifiers?.find(
          (el) => region?.id == el?.regionId
        );
        if (rentActiveMod) {
          rentModifiersTableData.push({
            id: rentActiveMod?.id,
            regionId: region?.id,
            regionName: region?.abbreviation,
            modifier: rentActiveMod?.modifier,
            parsedModifier: `${rentActiveMod?.modifier}qq/ha`,
          });
        } else {
          rentModifiersTableData.push({
            id: null,
            regionId: region?.id,
            regionName: region?.abbreviation,
            modifier: null,
            parsedModifier: "-",
          });
        }
        
        // Activity-based modifiers
        for (const activity of this.activities) {
          // Ensure the activity has all required properties
          if (!activity || !activity.id) {
            console.warn("Invalid activity:", activity);
            continue;
          }
          
          // Yield Modifier
          let yieldActiveMod = null;
          if (this.yieldModifiers && this.yieldModifiers.length > 0) {
            yieldActiveMod = this.yieldModifiers.find(
              (el) => region?.id == el?.regionId && activity?.id == el?.activityId
            );
          }
          
          yieldModifiersTableData.push({
            id: yieldActiveMod?.id || null,
            regionId: region?.id,
            regionName: region?.abbreviation,
            activityId: activity?.id,
            activityName: activity?.name,
            speciesId: activity?.speciesId,
            modifier: yieldActiveMod?.modifier || 1,
            parsedModifier: yieldActiveMod ? `${yieldActiveMod.modifier * 100}%` : "100%",
          });
          
          // Freight Modifier
          let freightActiveMod = null;
          if (this.freightModifiers && this.freightModifiers.length > 0) {
            freightActiveMod = this.freightModifiers.find(
              (el) => region?.id == el?.regionId && activity?.id == el?.activityId
            );
          }
          
          freightModifiersTableData.push({
            id: freightActiveMod?.id || null,
            regionId: region?.id,
            regionName: region?.abbreviation,
            activityId: activity?.id,
            activityName: activity?.name,
            speciesId: activity?.speciesId,
            modifier: freightActiveMod?.modifier || 1,
            parsedModifier: freightActiveMod ? `${freightActiveMod.modifier * 100}%` : "100%",
          });
          
          // Cost item family modifiers
          for (const costItemFamily of this.costItemFamiliesFiltered) {
            let costItemFamilyActiveMod = null;
            if (this.costItemFamilyModifiers && this.costItemFamilyModifiers.length > 0) {
              costItemFamilyActiveMod = this.costItemFamilyModifiers.find(
                (el) => 
                  region?.id == el?.regionId && 
                  activity?.id == el?.activityId &&
                  costItemFamily?.id == el?.costItemFamilyId
              );
            }
            
            costItemFamilyModifiersTableData.push({
              id: costItemFamilyActiveMod?.id || null,
              regionId: region?.id,
              regionName: region?.abbreviation,
              activityId: activity?.id,
              activityName: activity?.name,
              speciesId: activity?.speciesId,
              costItemFamilyId: costItemFamily?.id,
              costItemFamilyName: costItemFamily?.name,
              modifier: costItemFamilyActiveMod?.modifier || 1,
              parsedModifier: costItemFamilyActiveMod ? `${costItemFamilyActiveMod.modifier * 100}%` : "100%",
            });
          }
        }
      }
      
      return {
        yield: yieldModifiersTableData,
        freight: freightModifiersTableData,
        rent: rentModifiersTableData,
        costItemFamily: costItemFamilyModifiersTableData,
      };
    },

    yieldModifiersTableData() {
      return this.modifiersTableData?.yield;
    },
    freightModifiersTableData() {
      return this.modifiersTableData?.freight;
    },
    rentModifiersTableData() {
      return this.modifiersTableData?.rent;
    },
    priceModifiersTableData() {
      if (!this.activities || !this.activities.length) {
        console.warn("No activities available for price modifiers");
        return [];
      }
      
      let priceModifiersTableData = [];
      
      for (const activity of this.activities) {
        if (!activity || !activity.id) {
          console.warn("Invalid activity for price modifier:", activity);
          continue;
        }
        
        let priceActiveMod = null;
        if (this.priceModifiers && this.priceModifiers.length > 0) {
          priceActiveMod = this.priceModifiers.find(
            (el) => activity?.id == el?.activityId
          );
        }
        
        priceModifiersTableData.push({
          id: priceActiveMod?.id || null,
          activityId: activity?.id,
          activityName: activity?.name,
          speciesId: activity?.speciesId,
          modifier: priceActiveMod?.modifier || 1,
          parsedModifier: priceActiveMod ? `${priceActiveMod.modifier * 100}%` : "100%",
        });
      }
      
      return priceModifiersTableData;
    },
    costItemFamilyModifiersTableData() {
      return this.modifiersTableData?.costItemFamily;
    },

    activeSelectedModifiers() {
      return this.filteredModTableData?.filter((el) => el?.id !== null);
    },
    inActiveSelectedModifiers() {
      return this.filteredModTableData?.filter((el) => el?.id === null);
    },
    hasActiveModifiersInTab() {
      if (this.tab == this.yieldTab) {
        return this.yieldModifiers?.length > 0;
      } else if (this.tab == this.priceTab) {
        return this.priceModifiers?.length > 0;
      } else if (this.tab == this.costItemFamilyTab) {
        return this.costItemFamilyModifiers?.length > 0;
      } else if (this.tab == this.freightTab) {
        return this.freightModifiers?.length > 0;
      } else if (this.tab == this.rentTab) {
        return this.rentModifiers?.length > 0;
      }
      return false;
    },
    hasActiveCampaignModifiers() {
      if (
        this.yieldModifiers?.length > 0 ||
        this.priceModifiers?.length > 0 ||
        this.costItemFamilyModifiers?.length > 0 ||
        this.freightModifiers?.length > 0 ||
        this.rentModifiers?.length > 0
      ) {
        return true;
      }
      return false;
    },

    modTableData() {
      if (this.modifiersTypeFilter === "all") {
        if (this.tab == this.yieldTab) {
          return this.yieldModifiersTableData;
        } else if (this.tab == this.priceTab) {
          return this.priceModifiersTableData;
        } else if (this.tab == this.costItemFamilyTab) {
          return this.costItemFamilyModifiersTableData;
        } else if (this.tab == this.freightTab) {
          return this.freightModifiersTableData;
        } else if (this.tab == this.rentTab) {
          return this.rentModifiersTableData;
        }
      }
      if (this.modifiersTypeFilter === "activeOnly") {
        // Actives have ids
        if (this.tab == this.yieldTab) {
          return this.yieldModifiersTableData.filter((el) => el?.id !== null);
        } else if (this.tab == this.priceTab) {
          return this.priceModifiersTableData.filter((el) => el?.id !== null);
        } else if (this.tab == this.costItemFamilyTab) {
          return this.costItemFamilyModifiersTableData.filter(
            (el) => el?.id !== null
          );
        } else if (this.tab == this.freightTab) {
          return this.freightModifiersTableData.filter((el) => el?.id !== null);
        } else if (this.tab == this.rentTab) {
          return this.rentModifiersTableData.filter((el) => el?.id !== null);
        }
      }
      if (this.modifiersTypeFilter === "inActiveOnly") {
        // Inactives don't have ids
        if (this.tab == this.yieldTab) {
          return this.yieldModifiersTableData.filter((el) => el?.id === null);
        } else if (this.tab == this.priceTab) {
          return this.priceModifiersTableData.filter((el) => el?.id === null);
        } else if (this.tab == this.costItemFamilyTab) {
          return this.costItemFamilyModifiersTableData.filter(
            (el) => el?.id === null
          );
        } else if (this.tab == this.freightTab) {
          return this.freightModifiersTableData.filter((el) => el?.id === null);
        } else if (this.tab == this.rentTab) {
          return this.rentModifiersTableData.filter((el) => el?.id === null);
        }
      }
      return [];
    },

    filteredModTableData() {
      let data = this.modTableData;
      
      if (this.regionIdFilter) {
        data = data.filter((el) => el?.regionId == this.regionIdFilter);
      }
      
      // Filter by activity ID if specified
      if (this.activityIdFilter) {
        data = data.filter((el) => el?.activityId == this.activityIdFilter);
      }
      // Otherwise filter by species ID if specified
      else if (this.speciesIdFilter) {
        data = data.filter((el) => el?.speciesId == this.speciesIdFilter);
      }
      
      if (this.costItemFamilyIdFilter) {
        data = data.filter(
          (el) => el?.costItemFamilyId == this.costItemFamilyIdFilter
        );
      }
      
      return data;
    },
    modTableHeaders() {
      if (this.tab == this.yieldTab) {
        return this.yieldModifiersDataTableHeaders;
      } else if (this.tab == this.priceTab) {
        return this.priceModifiersDataTableHeaders;
      } else if (this.tab == this.costItemFamilyTab) {
        return this.costItemFamilyModifiersDataTableHeaders;
      } else if (this.tab == this.freightTab) {
        return this.freightModifiersDataTableHeaders;
      } else if (this.tab == this.rentTab) {
        return this.rentModifiersDataTableHeaders;
      }
      return "";
    },
    modTableTypeName() {
      if (this.tab == this.yieldTab) {
        return "Rinde";
      } else if (this.tab == this.priceTab) {
        return "Precio";
      } else if (this.tab == this.costItemFamilyTab) {
        return "Costos de Familia";
      } else if (this.tab == this.freightTab) {
        return "Flete";
      } else if (this.tab == this.rentTab) {
        return "Alquiler";
      }
      return "";
    },
    activeTabName() {
      if (this.tab == this.yieldTab) {
        return "yield";
      } else if (this.tab == this.priceTab) {
        return "price";
      } else if (this.tab == this.costItemFamilyTab) {
        return "costItemFamily";
      } else if (this.tab == this.freightTab) {
        return "freight";
      } else if (this.tab == this.rentTab) {
        return "rent";
      }
      return "cashflow";
    },
    modTableEndpoint() {
      if (this.tab == this.yieldTab) {
        return "/yield";
      } else if (this.tab == this.priceTab) {
        return "/price";
      } else if (this.tab == this.costItemFamilyTab) {
        return "/cost_item_family";
      } else if (this.tab == this.freightTab) {
        return "/freight";
      } else if (this.tab == this.rentTab) {
        return "/rent";
      }
      return "";
    },
    alertUserModifiersFiltersApplied() {
      let typeOfModifiers =
        this.modifiersTypeFilter == "inActiveOnly"
          ? "INACTIVOS"
          : this.modifiersTypeFilter == "activeOnly"
          ? "ACTIVOS"
          : "";
      if (!this.allowEdit) {
        return "";
      }
      
      if (
        !this.speciesIdFilter &&
        !this.regionIdFilter &&
        !this.activityIdFilter &&
        !this.costItemFamilyIdFilter
      ) {
        return `¡Se estan modificando TODOS los elementos ${typeOfModifiers}!`;
      } else if (
        this.activityIdFilter &&
        !this.regionIdFilter &&
        !this.costItemFamilyIdFilter
      ) {
        return (
          `¡Se estan modificando TODOS los elementos ${typeOfModifiers} de la actividad ` +
          this.activities.find((el) => el.id == this.activityIdFilter).name +
          "!"
        );
      } else if (
        this.speciesIdFilter &&
        !this.regionIdFilter &&
        !this.activityIdFilter &&
        !this.costItemFamilyIdFilter &&
        // tab !== 1 is to prevent alerts when price tab is selected
        this.tab !== this.priceTab
      ) {
        return (
          `¡Se estan modificando TODOS los elementos ${typeOfModifiers} de la especie ` +
          this.species.find((el) => el.id == this.speciesIdFilter).name +
          "!"
        );
      } else if (
        !this.speciesIdFilter &&
        !this.activityIdFilter &&
        this.regionIdFilter &&
        !this.costItemFamilyIdFilter
      ) {
        return (
          `¡Se estan modificando TODOS los elementos ${typeOfModifiers} de la region ` +
          this.regions.find((el) => el.id == this.regionIdFilter).abbreviation +
          "!"
        );
      } else if (
        !this.speciesIdFilter &&
        !this.activityIdFilter &&
        !this.regionIdFilter &&
        this.costItemFamilyIdFilter
      ) {
        return (
          `¡Se estan modificando TODOS los elementos ${typeOfModifiers} de la familia ` +
          this.costItemFamiliesFiltered.find(
            (el) => el.id == this.costItemFamilyIdFilter
          ).name +
          "!"
        );
      }
      return "";
    },
    campaignKpis() {
      if (!this.campaignMetrics?.kpi || !this.campaignSimulationMetrics?.kpi) {
        return [];
      } else {
        return makeCampaignSimulationInfoGrid(
          this.campaignMetrics?.kpi,
          this.campaignSimulationMetrics?.kpi
        );
      }
    },
    campaignKpi() {
      if (!this.campaignKpis.length > 0) {
        return [];
      } else {
        return [this.campaignKpis?.[0]];
      }
    },
    campaignKpiWithoutPct() {
      if (!this.campaignKpis.length > 0) {
        return [];
      } else {
        // Create a deep copy and remove the valuePct property
        const kpiWithoutPct = JSON.parse(JSON.stringify(this.campaignKpis[0]));
        kpiWithoutPct.forEach(item => delete item.valuePct);
        return [kpiWithoutPct];
      }
    },
    campaignSimulationKpi() {
      if (!this.campaignKpis.length > 1) {
        return [];
      } else {
        return [this.campaignKpis?.[1]];
      }
    },
    campaignSimulationKpiWithPct() {
      if (!this.campaignKpis.length > 1 || !this.campaignKpis[0]) {
        return [];
      } else {
        // Copy the simulation KPIs
        const simulationKpisWithPct = JSON.parse(JSON.stringify(this.campaignKpis[1]));
        // Copy the percentage values from the campaign KPIs to the simulation KPIs
        for (let i = 0; i < this.campaignKpis[0].length && i < simulationKpisWithPct.length; i++) {
          if (this.campaignKpis[0][i].valuePct !== undefined) {
            simulationKpisWithPct[i].valuePct = this.campaignKpis[0][i].valuePct;
          }
        }
        return [simulationKpisWithPct];
      }
    },
    campaignTotalHaToolTip() {
      return campaignLevelTotalHaToolTip;
    },
    campaignStartDate() {
      return this.singleAbstractCampaign?.startDate;
    },
    campaignEndDate() {
      return this.singleAbstractCampaign?.endDate;
    },
    cashflowEndMonth() {
      let date = new Date(this.campaignEndDate);

      return date.getMonth() + 1;
    },
    cashflowStartMonth() {
      let date = new Date(this.campaignStartDate);

      return date.getMonth() + 1;
    },
    cashflowEndYear() {
      let date = new Date(this.campaignEndDate);

      return date.getFullYear();
    },
    cashflowStartYear() {
      let date = new Date(this.campaignStartDate);

      return date.getFullYear();
    },
    // wip
    isCampaignActivitiesEmpty() {
      return this.campaignActivities.length === 0;
    },
    engagementCashflowColumns() {
      return unPackAndProcessCashflowKeys(this.campaignCashflow?.keys);
    },
    engagementCashflowRows() {
      return this.campaignCashflow?.rows;
    },
    campaignId() {
      return parseInt(this.$route.params.id2);
    },
    userLockSimulationName() {
      return true;
    },
    isUserManager() {
      return this.userType == "manager";
    },
    userType() {
      return this.userTokenInfo?.utype;
    },
    userId() {
      return this.userTokenInfo?.sub;
    },
    userName() {
      return this.userTokenInfo?.uname;
    },
    isLock() {
      return this.singleCampaign.userIdSimulationLock !== null;
    },
    userHasLock() {
      return this.singleCampaign.userIdSimulationLock == this.userId;
    },
    processedSingleCampaign() {
      return {
        id: this.singleCampaign.id,
        version: this.singleCampaign.version,
        name: this.singleAbstractCampaign.name,
        processedName:
          this.singleAbstractCampaign.name + " v" + this.singleCampaign.version,
        endDate: this.singleAbstractCampaign.endDate?.slice(0, 10),
        startDate: this.singleAbstractCampaign.startDate?.slice(0, 10),
        simulationLockDate: parseToLocaleString(
          new Date(this.singleCampaign?.userLockDatetime)
        ),
      };
    },
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.checkForKeyShortCut);
  },
  async mounted() {
    window.addEventListener("keydown", this.checkForKeyShortCut);
    if (this.isFullLoad) {
      this.$store.dispatch("setPageLoadingStatus", true);

      await this.$store.dispatch("getSingleCampaign", this.$route.params.id2);
      await this.$store.dispatch("getCostItemFamily");
      await this.$store.dispatch("getEstablishments");
      
      // Make sure we get activities before species
      await this.$store.dispatch("getActivities");
      await this.$store.dispatch("getSpecies");
      
      await Promise.all([
        this.getAllActiveModifiers(),
        this.$store.dispatch(
          "getSingleAbstractCampaign",
          this.$route.params.id1
        ),
        this.$store.dispatch("getCampaignActivities", {
          filterKey: "campaign_id",
          filterValue: this.$route.params.id2,
        }),
      ]);

      document.title = this.processedSingleCampaign?.processedName;
      if (!this.isCampaignActivitiesEmpty) {
        await Promise.all([
          this.getCampaignSimulationMetrics(),
          this.getCampaignMetrics(),
        ]);
      }
      this.getUserLockName();

      this.$store.dispatch("setPageLoadingStatus", false);
    }
  },
  components: {
    AbstractDraftMetricDataTable,
    InfoGrid,
    DenseCashflowTable,
  },
};
</script>

<style>
.v-overlay--active {
  border-color: #00b144 !important;
  border-radius: 5px !important;
}

@keyframes blinking {
  50% {
    opacity: 0;
  }
}

.blink {
  animation: blinking ease-out 2s infinite;
}

.simulator-container {
  background-color: #f5f7fa;
}

.campaign-header-card {
  background-color: white !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.kpi-card {
  background-color: white !important;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.kpi-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08) !important;
  transform: translateY(-2px);
}

.tabs-card {
  background-color: white !important;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.content-card {
  background-color: transparent !important;
}

.filter-card {
  background-color: white !important;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.control-card {
  background-color: white !important;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.filter-select {
  min-width: 120px;
}

.lock-overlay {
  /* backdrop-filter: blur(1px); */ /* Removed blur */
}

/* Slider customization */
.v-slider__thumb {
  transition: transform 0.2s;
}

.v-slider--is-active .v-slider__thumb {
  transform: scale(1.2);
}

.v-slider__thumb-label {
  background-color: #1976d2 !important;
}

/* Custom scrollbar for tables */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #9e9e9e;
}
</style>
